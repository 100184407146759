import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authedGuard } from './core/guards/auth.guard';
import { unauthGuard } from './core/guards/unauth.guard';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
		canActivate: [unauthGuard],
	},
	{
		path: '',
		loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
		canActivate: [authedGuard],
	},
	{
		path: '**',
		redirectTo: '',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
