import { Pipe, PipeTransform } from '@angular/core';

import { Tool } from '../../data/models/clients.model';

export const toolNameMapping: {
	[key in Tool]: string;
} = {
	[Tool.RAPID_LPR]: 'Rapid LPR',
	[Tool.FINANCIAL_MODELING]: 'Financial Modeling'
}

@Pipe({
  name: 'formatTool'
})
export class FormatToolPipe implements PipeTransform {

  transform(value: string): unknown {
    return toolNameMapping[value];
  }

}
