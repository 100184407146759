import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, firstValueFrom, map, Observable, Subscription } from 'rxjs';

import { UsersDao } from '../../../data/daos/users.dao';
import { User } from '../../../data/models/user.model';
import { AddEditUserComponent, AddEditUserResponse } from '../../../portal/users/add-edit-user/add-edit-user.component';
import { DIALOG_WIDTH } from '../../constants/dialog';
import { LocalStorageUtil } from '../../utils/local-storage.util';
import { SideNavService } from '../side-nav/side-nav.service';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';

@Component({
	selector: 'dix-heading',
	templateUrl: './heading.component.html',
	styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent implements OnInit, OnDestroy {
	currentUser$: Observable<User>;
	currentUserId = LocalStorageUtil.getUserId();
	activeClientId$: Observable<string>;
	subscription = new Subscription();
	constructor(
		private auth: AngularFireAuth,
		private dialog: MatDialog,
		private userDao: UsersDao,
		public sideNav: SideNavService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.subscription.add(
			this.router.events
				.pipe(filter((event) => event instanceof NavigationEnd))
				.subscribe(this.updateActiveClient.bind(this)),
		);
		this.updateActiveClient();
	}

	updateActiveClient() {
		let currentRoute = this.route;
		while (currentRoute.firstChild) {
			currentRoute = currentRoute.firstChild;
		}
		this.activeClientId$ = currentRoute.params.pipe(map((params) => params.clientId));
	}

	async ngOnInit(): Promise<void> {
		this.currentUser$ = this.userDao.doc(LocalStorageUtil.getUserId()).doc.valueChanges({ idField: 'id' });
	}

	logout() {
		this.auth.signOut();
	}

	async changePassword() {
		this.dialog.open(ResetPasswordDialogComponent, {
			autoFocus: false,
			width: '400px',
			height: '450px',
		});
	}

	async editProfile() {
		const currentUser = await firstValueFrom(this.currentUser$);
		const modal = this.dialog.open(AddEditUserComponent, {
			autoFocus: false,
			width: DIALOG_WIDTH,
			data: { existing: currentUser, id: LocalStorageUtil.getUserId() },
		});
		const data = await firstValueFrom<AddEditUserResponse>(modal.afterClosed());
		if (data.user) {
			await this.userDao.updateUser(data.id, data.user);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
