<div class="profile-picture-container">
	<input type="file" id="fileInput" (change)="uploadFile($event)" hidden />
	<img *ngIf="imageUrl" [src]="imageUrl" alt="Profile Picture" class="profile-picture" />
	<div *ngIf="!imageUrl && !isImageLoading" class="profile-placeholder"></div>
	<div *ngIf="isImageLoading" class="d-flex align-items-center justify-content-center h-100">
		<mat-spinner color="accent" diameter="50" mode="determinate" [value]="imageLoadingPercent$ | async"></mat-spinner>
		<div class="position-absolute">{{ imageLoadingPercent$ | async | number: '1.0-0' }}%</div>
	</div>
	<label for="fileInput" class="edit-icon" *ngIf="!hideEditButton">
		<mat-icon>edit</mat-icon>
	</label>
</div>
