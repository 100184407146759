import { Directive } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import { BaseModel, Data } from '../models/base.model';
import { CollectionInstance } from './collection-instance';
import { DocInstance } from './doc-instance';

@Directive()
export abstract class RootBaseDao<T extends BaseModel> {
	abstract collectionName: string;

	constructor(protected afs: AngularFirestore) {}

	documentPath(docId: string) {
		return `${this.collectionName}/${docId}`;
	}

	collection(): CollectionInstance<T> {
		return new CollectionInstance(this.afs, this.collectionName);
	}

	doc(docId: string): DocInstance<T> {
		return new DocInstance(this.afs, this.documentPath(docId));
	}

	docFromPath(path: string): DocInstance<T> {
		return new DocInstance(this.afs, path);
	}

	add(obj: Data<T>): Promise<DocumentReference<Data<T>>> {
		return this.afs.collection<Data<T>>(this.collectionName).add(obj);
	}
}
