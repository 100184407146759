import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { first, map } from 'rxjs';

export const RETURN_URL_KEY = 'returnUrl';

// eslint-disable-next-line func-style
export const authedGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const router = inject(Router);
	const auth = inject(AngularFireAuth);

	return auth.user.pipe(
		first(),
		map((user) => {
			if (!user) {
				const returnUrl = `${window.location.origin}${state.url}`;
				const redirectUrl = `/auth?${RETURN_URL_KEY}=${returnUrl}`;
				return router.parseUrl(redirectUrl);
			}
			return true;
		})
	);
};
