import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SideNavService {
	isCollapsed: boolean = false;

	toggleCollapsed() {
		this.isCollapsed = !this.isCollapsed;
	}
}
