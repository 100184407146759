import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import { LocalStorageUtil } from 'src/app/shared/utils/local-storage.util';

import { Client } from '../models/clients.model';
import { RootBaseDao } from './root-base.dao';

@Injectable({ providedIn: 'root' })
export class ClientsDao extends RootBaseDao<Client> {
	collectionName = 'clients';

	streamClients() {
		return this.collection().streamAll((ref) => {
			if (!LocalStorageUtil.getIsInternal()) {
				return ref.where(firebase.firestore.FieldPath.documentId(), 'in', LocalStorageUtil.getClientIds());
			}
			return ref;
		});
	}

	async deleteClient(id: string) {
		await this.afs.firestore.runTransaction(async (t) => {
			const clientData = (await t.get(this.doc(id).doc.ref)).data();
			t.set(this.afs.collection('archivedClients').doc(id).ref, clientData);
			t.delete(this.doc(id).doc.ref);
		});
	}

	async updateClient(id: string, data: Partial<Client>) {
		await this.doc(id).doc.update(data);
	}
}
