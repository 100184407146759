<div mat-dialog-title>{{ data.title }}</div>

<mat-dialog-content>
	<div [class.fw-bold]="data.details">{{ data.question }}</div>
	<div *ngIf="data.details" class="details mt-4">{{ data.details }}</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close [disabled]="isSubmitting">
		{{ data.cancel ?? 'Cancel' }}
	</button>
	<button
		mat-raised-button
		[color]="data.isConfirmationNegative ? 'warn' : 'primary'"
		(click)="onConfirm()"
		type="submit"
		[disabled]="isSubmitting"
	>
		{{ data.confirm }}
	</button>
</mat-dialog-actions>
