import { Component } from '@angular/core';

import { AuthService } from './core/services/auth.service';

@Component({
	selector: 'dix-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	constructor(private auth: AuthService) {
		this.auth.init();
	}
}
