import { Action, AngularFirestore, AngularFirestoreDocument, DocumentSnapshot } from '@angular/fire/compat/firestore';

import { firstValueFrom, map, Observable } from 'rxjs';

export class DocInstance<T> {
	readonly doc: AngularFirestoreDocument<T>;

	constructor(afs: AngularFirestore, path: string) {
		this.doc = afs.doc<T>(path);
	}

	stream(): Observable<T | null> {
		return this.doc.snapshotChanges().pipe(this.mapAction);
	}

	async get(): Promise<T | null> {
		return firstValueFrom(this.stream());
	}

	private mapAction = map((action: Action<DocumentSnapshot<T>>) =>
		action.payload.exists ? { ...action.payload.data(), id: action.payload.id, path: action.payload.ref.path } : null,
	);
}
