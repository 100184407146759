import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { LogService } from './logging';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
	constructor(private injector: Injector) {}

	async handleError(error: Error) {
		const logService = this.injector.get(LogService);
		if (!error.message.includes('auth/')) {
			logService.error(error.message, {}, error);
		}
	}
}
