import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { environment } from 'src/environments/environment';

import { LogModule } from '../logging';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationDialogComponent } from './confirm/confirmation-dialog.component';
import { NotificationDialogComponent } from './notify/notification-dialog.component';

@NgModule({
	declarations: [ConfirmationDialogComponent, NotificationDialogComponent],
	imports: [
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAuthModule,
		AngularFireFunctionsModule,
		LogModule.initializeLogger(environment.logging),
		MatButtonModule,
		MatDialogModule,
		MatSnackBarModule,
		SharedModule,
	],
	providers: [
		{
			provide: USE_AUTH_EMULATOR,
			useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined,
		},
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
		}
	}
}
