import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import firebase from 'firebase/compat/app';

import { UsersDao } from '../../data/daos/users.dao';
import { UserClaims } from '../../data/models/user.model';
import { LocalStorageUtil } from '../../shared/utils/local-storage.util';

@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(
		private auth: AngularFireAuth,
		private usersDao: UsersDao,
	) {}

	async init() {
		this.auth.authState.subscribe(async (user) => {
			if (!user) {
				if (!/^(\/login$)|(\/auth\/)/.test(window.location.pathname)) {
					window.location.href = '/auth/login';
				}
				LocalStorageUtil.clear();
			} else {
				await AuthService.setLocalStorageForUser(user);
				await this.updateAuthDetails(user);
			}
		});
	}

	static async setLocalStorageForUser(user: firebase.User) {
		const claims = (await user.getIdTokenResult()).claims as UserClaims;
		const { isInternal, clientIds, role } = claims as UserClaims;
		LocalStorageUtil.setIsInternal(isInternal);
		LocalStorageUtil.setRole(role);
		LocalStorageUtil.setClientIds(clientIds);
		LocalStorageUtil.setUserId(user.uid);
	}

	async updateAuthDetails(user: firebase.User) {
		const lastLoggedIn = user.metadata.lastSignInTime;
		const isEmailVerified = user.emailVerified;
		await this.usersDao.updateUser(user.uid, {
			lastLoggedIn: firebase.firestore.Timestamp.fromDate(new Date(lastLoggedIn)),
			isEmailVerified,
		});
	}
}
