import { UserRole } from '../../data/models/user.model';

enum LocalStorageKeys {
	isInternal = 'isInternal',
	clientId = 'clientIds',
	role = 'role',
	userId = 'userId',
}

export class LocalStorageUtil {
	static getIsInternal(): boolean {
		return localStorage.getItem(LocalStorageKeys.isInternal) === 'true';
	}

	static setIsInternal(isInternal: boolean) {
		localStorage.setItem(LocalStorageKeys.isInternal, String(isInternal));
	}

	static getRole(): UserRole {
		return localStorage.getItem(LocalStorageKeys.role) as UserRole;
	}

	static setRole(role: UserRole) {
		localStorage.setItem(LocalStorageKeys.role, role);
	}

	static getClientIds(): string[] | undefined {
		const clientId = localStorage.getItem(LocalStorageKeys.clientId);
		if (clientId === 'undefined' || clientId === 'null') return undefined;
		try {
			return JSON.parse(clientId);
		} catch (err) {
			return undefined;
		}
	}

	static setClientIds(clientIds: string[]) {
		localStorage.setItem(LocalStorageKeys.clientId, JSON.stringify(clientIds));
	}

	static setUserId(userId: string) {
		localStorage.setItem(LocalStorageKeys.userId, userId);
	}

	static getUserId(): string {
		return localStorage.getItem(LocalStorageKeys.userId);
	}

	static clear() {
		for (const key of Object.values(LocalStorageKeys)) {
			localStorage.removeItem(key);
		}
	}
}
