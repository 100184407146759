import { Component, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { firstValueFrom } from 'rxjs';

import { IMAGE_STORAGE_PATH } from '../profile-picture-selector/profile-picture-selector.component';

@Component({
	selector: 'dix-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
	private _identifier: string;

	get identifier() {
		return this._identifier;
	}

	@Input()
	set identifier(value: string) {
		this._identifier = value;
		const file = this.storage.ref(`${IMAGE_STORAGE_PATH}/${this.identifier}.png`);
		firstValueFrom(file.getDownloadURL())
			.then((url) => {
				this.src = url;
			})
			.catch(() => {}); // swallow the error
	}

	@Input() size: 'small' | 'medium' | 'large' = 'small';

	src: string;
	fallbackUrl: string = '/assets/default-profile-picture.png';

	constructor(private storage: AngularFireStorage) {}

	onError() {
		this.src = this.fallbackUrl;
	}
}
