import firebase from 'firebase/compat/app';
import { BaseModel } from 'src/app/data/models/base.model';

export interface User extends BaseModel {
	firstName: string;
	lastName: string;
	email: string;
	isActive: boolean;
	createdAt: firebase.firestore.Timestamp;
	lastAccessed: firebase.firestore.Timestamp;
	isEmailVerified: boolean;
	role: UserRole;
	clientIds?: string[];
	isInternal?: boolean;
}

export enum UserRole {
	ADMIN = 'ADMIN',
	USER = 'USER',
}

export interface UserClaims {
	isInternal: boolean;
	role: UserRole;
	clientIds?: string[];
}
