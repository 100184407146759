import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandlerService } from './error-handler';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, CoreModule, AppRoutingModule, BrowserAnimationsModule, SharedModule],
	bootstrap: [AppComponent],
	providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandlerService }],
})
export class AppModule {}
