import { Inject, Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { LogAppender } from './appenders/log-appender.service';
import { LogModule, LOG_APPENDER } from './log.module';
import { LogMessage } from './models/log-message.model';

/**
 * Core log service that holds the pipeline for log messages.
 * Must be a singleton.
 *
 * @export
 * @class LogCoreService
 */
@Injectable({ providedIn: LogModule })
export class LogCoreService {
	private messagesStream: Subject<LogMessage>;

	constructor(@Inject(LOG_APPENDER) appenders: LogAppender[]) {
		this.messagesStream = new Subject<LogMessage>();
		this.messages.subscribe((logMessage) => appenders.forEach((app) => app.write(logMessage)));
	}

	/**
	 * Observable stream of log messages.
	 * Meant for appenders to subscribe on.
	 *
	 * @readonly
	 * @type {Observable<LogMessage>}
	 * @memberof LogService
	 */
	public get messages(): Observable<LogMessage> {
		return this.messagesStream.asObservable();
	}

	/**
	 * Log a message.
	 *
	 * @memberof LogCoreService
	 */
	public log(message: LogMessage): void {
		this.messagesStream.next(message);
	}
}
