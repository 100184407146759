import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

import { AvatarComponent } from './components/avatar/avatar.component';
import { HeadingComponent } from './components/heading/heading.component';
import {
	ResetPasswordDialogComponent,
} from './components/heading/reset-password-dialog/reset-password-dialog.component';
import {
	ProfilePictureSelectorComponent,
} from './components/profile-picture-selector/profile-picture-selector.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { FormatEnumPipe } from './pipes/format-enum.pipe';
import { FormatToolPipe } from './pipes/format-tool.pipe';

@NgModule({
	declarations: [
		FormatEnumPipe,
		SideNavComponent,
		HeadingComponent,
		ProfilePictureSelectorComponent,
		AvatarComponent,
		FormatToolPipe,
		ResetPasswordDialogComponent,
	],
	imports: [
		CommonModule,
		MatIconModule,
		RouterModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatMenuModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule
	],
	exports: [
		CommonModule,
		FormsModule,
		FormatEnumPipe,
		SideNavComponent,
		HeadingComponent,
		ProfilePictureSelectorComponent,
		AvatarComponent,
		FormatToolPipe,
	],
})
export class SharedModule {}
