<div class="heading">
	<button mat-button [matMenuTriggerFor]="menu" id="profile-menu" *ngIf="currentUser$ | async as currentUser">
		<div class="d-flex align-items-center">
			<span class="me-3 mt-1">{{ currentUser.firstName }} {{ currentUser.lastName }}</span>
			<dix-avatar [identifier]="currentUser.id" size="medium"></dix-avatar>
		</div>
	</button>
	<mat-menu #menu="matMenu">
		<button mat-menu-item (click)="changePassword()">
			<mat-icon>manage_accounts</mat-icon>
			Change Password
		</button>
		<button mat-menu-item (click)="editProfile()">
			<mat-icon>edit</mat-icon>
			Edit Profile
		</button>
		<button mat-menu-item (click)="logout()">
			<mat-icon>logout</mat-icon>
			Logout
		</button>
	</mat-menu>
</div>
