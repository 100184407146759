import { ClassProvider } from '@angular/core';

import { LoggingConfig } from './log-config.model';

/**
 * Passed to the module to setup the configuration of the logger
 */
export interface LoggerSetupConfig extends LoggingConfig {
	defaultZone: string;
	appenders: ClassProvider[];
}
