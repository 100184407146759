import { BaseModel } from './base.model';

export enum Tool {
	RAPID_LPR = 'RAPID_LPR',
	FINANCIAL_MODELING = 'FINANCIAL_MODELING',
}

export interface Client extends BaseModel {
	name: string;
	state: string;
	tools: Tool[];
	powerBiWorkspaceId: string;
	powerBiReportId: string;
	hideInWebsite?: boolean;
}
