import firebase from 'firebase/compat/app';

interface ErrorCode {
	canonicalCode: number;
	status: number;
}

export interface FunctionsHttpErrorModel {
	code: firebase.functions.FunctionsErrorCode;
	details?: any;
	httpErrorCode: ErrorCode;
}

export class FunctionsHttpError extends Error implements FunctionsHttpErrorModel {
	code: firebase.functions.FunctionsErrorCode;
	details?: any;
	httpErrorCode: ErrorCode;

	constructor(httpError: FunctionsHttpErrorModel) {
		super(httpError.details?.message);
		this.code = httpError.code;
		this.details = httpError.details;
		this.httpErrorCode = httpError.httpErrorCode;
	}
}
