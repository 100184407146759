import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';
import { LogService } from 'src/app/logging';

import { Client } from '../../data/models/clients.model';
import { FunctionsHttpError, FunctionsHttpErrorModel } from '../models/functions-error.model';

type FunctionsResponse<T> = T | FunctionsHttpErrorModel;

function isError<T>(resp: FunctionsResponse<T>): resp is FunctionsHttpErrorModel {
	return (
		(resp as FunctionsHttpErrorModel)?.code !== undefined &&
		(resp as FunctionsHttpErrorModel)?.httpErrorCode !== undefined
	);
}

@Injectable({ providedIn: 'root' })
export class FunctionsService {
	constructor(
		private aff: AngularFireFunctions,
		private log: LogService,
	) {}

	async callFunction<Ti, To>(name: string, data: Ti, options?: firebase.functions.HttpsCallableOptions): Promise<To> {
		const callable = this.aff.httpsCallable<Ti, FunctionsResponse<To>>(name, options);
		let response: FunctionsResponse<To>;
		try {
			response = await firstValueFrom(callable(data));
			if (isError(response)) {
				throw new FunctionsHttpError(response);
			}
		} catch (err) {
			this.log.error(`Failed to execute ${name}`, {}, err);
			throw err;
		}
		return response;
	}

	async sendWelcomeMessage(data: { id: string; clientId: string }): Promise<void> {
		await this.callFunction('sendWelcomeMessage', data);
	}

	async addClient(id: string, client: Client): Promise<void> {
		await this.callFunction('addClient', { id, client });
	}

	async userIdFromEmail(email: string): Promise<string | undefined> {
		return this.callFunction('userIdFromEmail', { email });
	}
}
