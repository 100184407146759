import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import firebase from 'firebase/compat/app';

import { SnackBarService } from '../../../../core/services/snack-bar.service';
import { LogService } from '../../../../logging';

@Component({
	selector: 'dix-reset-password-dialog',
	templateUrl: './reset-password-dialog.component.html',
	styleUrls: ['./reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent {
	form = new FormGroup({
		oldPassword: new FormControl('', [Validators.required]),
		password: new FormControl('', [Validators.required]),
		confirmPassword: new FormControl('', [Validators.required]),
	});

	passwordMismatch = false;
	wrongPassword = false;
	loading = false;

	constructor(
		private auth: AngularFireAuth,
		private log: LogService,
		private snackbarService: SnackBarService,
		public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
	) {}

	async resetPassword() {
		if (this.form.invalid || this.loading) {
			return;
		}
		this.wrongPassword = false;
		this.passwordMismatch = this.form.controls.password.value !== this.form.controls.confirmPassword.value;
		if (this.passwordMismatch) {
			return;
		}
		this.loading = true;
		try {
			const currentUser = await this.auth.currentUser;
			const credential = firebase.auth.EmailAuthProvider.credential(
				currentUser.email,
				this.form.controls.oldPassword.value,
			);
			await currentUser.reauthenticateWithCredential(credential);
			await currentUser.updatePassword(this.form.controls.password.value);
			this.snackbarService.open('Password reset complete.');
			this.dialogRef.close();
		} catch (e) {
			if (e.code === 'auth/wrong-password') {
				this.wrongPassword = true;
				return;
			}
			this.snackbarService.open('An error occured while resetting the password. Please try again or contact support.');
			if (e.code) {
				this.log.info(`Failure to reset password: ${e.code}`, {}, e);
			} else {
				this.log.info('Failure to reset password', {}, e);
			}
		} finally {
			this.loading = false;
		}
	}
}
