import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class NotificationConfig {
	title: string;
	statement: string;
}

@Component({
	selector: 'dix-notification-dialog',
	templateUrl: './notification-dialog.component.html',
})
export class NotificationDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<NotificationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: NotificationConfig
	) {}
}
