// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ConsoleAppender, LOG_APPENDER, LoggerSetupConfig, LogLevel } from 'src/app/logging';
import { LogZone } from 'src/app/shared/enums/log-zone.enum';

import { EnvironmentConfig } from './environment-config';

export const environment: EnvironmentConfig = {
	production: false,
	useEmulators: false,
	firebaseConfig: {
		apiKey: 'AIzaSyDkbTp8Fv32MSAURjxcyXPG5danZ1i4aDg',
		authDomain: 'dixon-dev.firebaseapp.com',
		projectId: 'dixon-dev',
		storageBucket: 'dixon-dev-website',
		messagingSenderId: '920266147903',
		appId: '1:920266147903:web:263cc179944c1bd25a508a',
	},
	logging: {
		logLevel: LogLevel.Debug,
		zones: new Set<string>([]),
		rootZone: LogZone.GLOBAL,
		appenders: [{ provide: LOG_APPENDER, useClass: ConsoleAppender, multi: true }],
	} as LoggerSetupConfig,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
