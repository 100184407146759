import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { LogModule, LOGGER_CONFIG } from './log.module';
import { LoggingConfig } from './models/log-config.model';
import { LogLevel } from './models/log-level.model';

/**
 * Allow the user to modify the log settings live in the browser.
 *
 * @export
 * @class LogSettingsService
 */
@Injectable({ providedIn: LogModule })
export class LogSettingsService {
	private logConfig: LoggingConfig;
	private configChanges: Subject<LoggingConfig>;

	constructor(@Inject(LOGGER_CONFIG) config: LoggingConfig) {
		this.logConfig = config;
		this.configChanges = new BehaviorSubject<LoggingConfig>({ ...this.logConfig });
	}

	/**
	 * Observe changes to the logging config.
	 *
	 * @readonly
	 * @type {Observable<LoggingConfig>}
	 * @memberof LogSettingsService
	 */
	public get config(): Observable<LoggingConfig> {
		return this.configChanges.asObservable();
	}

	/**
	 * Modify the log level.
	 *
	 * @memberof LogSettingsService
	 */
	public set level(logLevel: LogLevel) {
		this.logConfig.logLevel = logLevel;
		this.updateConfig();
	}

	/**
	 * Set the zones.
	 *
	 * @memberof LogSettingsService
	 */
	public set zones(zones: string[]) {
		this.logConfig.zones = new Set<string>(zones);
		this.updateConfig();
	}

	/**
	 * Add a zone.
	 *
	 * @memberof LogSettingsService
	 */
	public addZone(zone: string): void {
		if (this.logConfig.zones.has(zone)) {
			return;
		}
		this.logConfig.zones.add(zone);
		this.updateConfig();
	}

	/**
	 * Remove a zone;
	 *
	 * @memberof LogSettingsService
	 */
	public removeZone(zone: string): void {
		if (!this.logConfig.zones.delete(zone)) {
			return;
		}
		this.updateConfig();
	}

	/**
	 * Publish a change for the config.
	 *
	 * @private
	 * @memberof LogSettingsService
	 */
	private updateConfig(): void {
		this.configChanges.next({ ...this.logConfig });
	}
}
