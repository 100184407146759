import capitalize from 'capitalize';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatEnum',
})
export class FormatEnumPipe implements PipeTransform {
	transform(value: string): string {
		if (value) {
			return capitalize.words(value.replace(/_/g, ' '));
		}
		return '';
	}
}
