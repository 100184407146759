import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanMatchFn, Router, UrlTree } from '@angular/router';

import { firstValueFrom } from 'rxjs';

// eslint-disable-next-line func-style, @typescript-eslint/no-unused-vars
export const unauthGuard: CanMatchFn = async (): Promise<boolean | UrlTree> => {
	const router = inject(Router);
	const auth = inject(AngularFireAuth);

	const user = await firstValueFrom(auth.user);
	if (user) {
		return router.parseUrl('/'); // Redirects to the root if authenticated
	}
	return true;
};
