/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { LogModule } from '../log.module';
import { LogLevel } from '../models/log-level.model';
import { LogMessage } from '../models/log-message.model';
import { LogAppender } from './log-appender.service';

const LOG_LEVEL_STYLES = new Map<LogLevel, string>();
LOG_LEVEL_STYLES.set(LogLevel.Trace, 'font-weight: bold; color: #B0BEC5;');
LOG_LEVEL_STYLES.set(LogLevel.Debug, 'font-weight: bold; color: #4CAF50;');
LOG_LEVEL_STYLES.set(LogLevel.Info, 'font-weight: bold; color: #0277BD;');
LOG_LEVEL_STYLES.set(LogLevel.Warn, 'font-weight: bold; color: #FFC107;');
LOG_LEVEL_STYLES.set(LogLevel.Error, 'font-weight: bold; color: #B71C1C;');
LOG_LEVEL_STYLES.set(LogLevel.Fatal, 'font-weight: bold; color: #B71C1C;');

@Injectable({ providedIn: LogModule })
export class ConsoleAppender implements LogAppender {
	/**
	 * Write a log message to the console.
	 *
	 * @param logMessage
	 * @memberof ConsoleAppender
	 */
	public write(logMessage: LogMessage): void {
		const style = LOG_LEVEL_STYLES.get(logMessage.level);

		if (logMessage.exception) {
			console.groupCollapsed(`${logMessage.timestamp.toLocaleString()} [${logMessage.levelText}] (${logMessage.zone})`);
			console.log(`%c${logMessage.message}`, style);
			if (logMessage.data != null) {
				console.log(logMessage.data);
			}
			if (logMessage.exception) {
				console.log(logMessage.exception);
			}
			console.groupEnd();
		} else {
			const message = `%c${logMessage.timestamp.toLocaleString()} [${logMessage.levelText}] (${logMessage.zone}) - %c${
				logMessage.message
			}`;
			if (logMessage.data != null) {
				console.log(message, style, '', logMessage.data);
			} else {
				console.log(message, style, '');
			}
		}
	}
}
