import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ReplaySubject, Subscription } from 'rxjs';
import { UsersDao } from 'src/app/data/daos/users.dao';

import { FunctionsService } from '../../../core/services/functions.service';
import { User, UserRole } from '../../../data/models/user.model';
import { LocalStorageUtil } from '../../../shared/utils/local-storage.util';

export interface AddEditUserData {
	existing?: Partial<User>;
	id: string;
	clientId?: string;
}

export interface AddEditUserResponse {
	user: Partial<User>;
	sendWelcomeEmail: boolean;
	id: string;
	preExistingUserId?: string;
}

@Component({
	selector: 'dix-add-edit-user',
	templateUrl: './add-edit-user.component.html',
	styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnDestroy {
	activeUserId = LocalStorageUtil.getUserId();
	roles = Object.keys(UserRole);
	isEdit: boolean;
	isAdmin = LocalStorageUtil.getRole() === UserRole.ADMIN;
	formGroup: FormGroup<{
		name: FormControl<string>;
		lastName: FormControl<string>;
		email: FormControl<string>;
		role: FormControl<UserRole>;
		sendWelcomeEmail: FormControl<boolean>;
	}>;

	preExistingUser$: ReplaySubject<User | null> = new ReplaySubject(1);
	preExistingUserId: string;
	subscription = new Subscription();

	constructor(
		public dialogRef: MatDialogRef<AddEditUserComponent, AddEditUserResponse>,
		@Inject(MAT_DIALOG_DATA) public data: AddEditUserData,
		private formBuilder: FormBuilder,
		private usersDao: UsersDao,
		private functionsService: FunctionsService,
	) {
		this.isEdit = !!data.existing;
		this.formGroup = this.formBuilder.group({
			name: [data.existing?.firstName ?? '', [Validators.required]],
			lastName: [data.existing?.lastName ?? '', [Validators.required]],
			role: [data.existing?.role, [Validators.required]],
			email: [data.existing?.email ?? '', { updateOn: 'blur', validators: [Validators.required, Validators.email] }],
			sendWelcomeEmail: [false],
		});
		this.functionsService.userIdFromEmail('ping').then();
		this.subscription.add(
			this.formGroup.controls.email.valueChanges.subscribe(async (email) => {
				const userId = await this.functionsService.userIdFromEmail(email);
				const result = await this.usersDao.doc(userId).get();
				if (!this.data.clientId && result) {
					this.formGroup.controls.email.setErrors({ exists: true });
					return;
				}
				this.preExistingUser$.next(result ?? null);
				this.preExistingUserId = userId;
				if (result?.clientIds?.includes(this.data.clientId) || result?.isInternal) {
					this.formGroup.controls.email.setErrors({ exists: true });
				} else if (this.formGroup.controls.email.errors?.exists) {
					this.formGroup.controls.email.updateValueAndValidity();
				}
			}),
		);
		this.subscription.add(
			this.preExistingUser$.subscribe((user) => {
				if (user) {
					const { firstName, lastName, role } = user;
					this.formGroup.controls.name.setValue(firstName);
					this.formGroup.controls.lastName.setValue(lastName);
					this.formGroup.controls.role.setValue(role);
					this.formGroup.controls.lastName.disable();
					this.formGroup.controls.name.disable();
					this.formGroup.controls.role.disable();
					this.formGroup.controls.sendWelcomeEmail.disable();
				} else {
					this.formGroup.controls.lastName.enable();
					this.formGroup.controls.name.enable();
					this.formGroup.controls.role.enable();
					this.formGroup.controls.sendWelcomeEmail.enable();
				}
			}),
		);
	}

	save() {
		if (!this.formGroup.valid) {
			return;
		}
		this.dialogRef.close({
			user: {
				firstName: this.formGroup.controls.name.value,
				lastName: this.formGroup.controls.lastName.value,
				email: this.formGroup.controls.email.value,
				role: this.formGroup.controls.role.value,
				id: this.data.id,
			},
			id: this.data.id,
			sendWelcomeEmail: this.formGroup.controls.sendWelcomeEmail.value,
			preExistingUserId: this.preExistingUserId,
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
