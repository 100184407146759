export class AppRoute {
	static readonly Auth = '/auth';
	static readonly Users = 'users';
	static readonly Clients = 'clients';
	static readonly LprReport = 'lpr-report';
}

export enum RouteParams {
	ClientId = 'clientId',
}
