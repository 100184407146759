import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { LogAppender } from './appenders/log-appender.service';
import { LoggerSetupConfig } from './models/logger-setup-config.model';

export const LOGGER_CONFIG = new InjectionToken<LoggerSetupConfig>('logging.config');
export const DEFAULT_LOG_ZONE: InjectionToken<string> = new InjectionToken<string>('logging.defaultZone');
export const LOG_APPENDER: InjectionToken<LogAppender> = new InjectionToken<string>('logging.appender');

@NgModule()
export class LogModule {
	static initializeLogger(config: LoggerSetupConfig): ModuleWithProviders<LogModule> {
		return {
			ngModule: LogModule,
			providers: [
				{ provide: LOGGER_CONFIG, useValue: config },
				{ provide: DEFAULT_LOG_ZONE, useValue: config.rootZone },
				...config.appenders,
			],
		};
	}
}
