<div class="col d-flex flex-column justify-content-center align-items-center h-100">
	<h1>Enter a new password</h1>
	<div id="input-wrapper">
		<form [formGroup]="form" (submit)="resetPassword()">
			<div class="form-group">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Old Password</mat-label>
					<input matInput placeholder="Old Password" type="password" formControlName="oldPassword" />
					<mat-error *ngIf="form.controls.oldPassword.hasError('required')">Old password is required</mat-error>
				</mat-form-field>
				<mat-error *ngIf="wrongPassword" class="invalid-error">Invalid password</mat-error>
			</div>
			<div class="form-group">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Password</mat-label>
					<input matInput placeholder="Password" type="password" formControlName="password" />
					<mat-error *ngIf="form.controls.password.hasError('required')">Password is required</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Confirm Password</mat-label>
					<input matInput placeholder="Confirm Password" type="password" formControlName="confirmPassword" />
					<mat-error *ngIf="form.controls.confirmPassword.hasError('required')">Confirm Password is required</mat-error>
				</mat-form-field>
				<mat-error *ngIf="passwordMismatch" class="invalid-error">The passwords don't match</mat-error>
			</div>
			<button type="submit" mat-raised-button color="primary" [disabled]="loading">Change Password</button>
		</form>
	</div>
</div>
