import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';

import { UpdateData } from '../models/firestore.model';
import { User } from '../models/user.model';
import { RootBaseDao } from './root-base.dao';

@Injectable({ providedIn: 'root' })
export class UsersDao extends RootBaseDao<User> {
	collectionName = 'users';

	streamUsers(clientId: string) {
		return this.collection().streamAll((ref) =>
			clientId ? ref.where('clientIds', 'array-contains', clientId) : ref.where('isInternal', '==', true),
		);
	}

	deleteUser(id: string) {
		this.doc(id).doc.delete();
	}

	async updateUser(id: string, data: UpdateData<User>) {
		await this.doc(id).doc.update(data as Partial<User>);
	}

	async addUser(id: string, data: User): Promise<void> {
		return this.collection()
			.collection.doc(id)
			.set({
				...data,
				isActive: true,
				createdAt: firebase.firestore.Timestamp.now(),
			});
	}
}
