import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import firebase from 'firebase/compat/app';
import { filter, firstValueFrom, map, Observable, Subscription } from 'rxjs';

import { ClientsDao } from '../../../data/daos/clients.dao';
import { Client } from '../../../data/models/clients.model';
import { UserRole } from '../../../data/models/user.model';
import { AppRoute } from '../../constants/route';
import { LocalStorageUtil } from '../../utils/local-storage.util';
import { SideNavService } from './side-nav.service';

@Component({
	selector: 'dix-side-nav',
	templateUrl: './side-nav.component.html',
	styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnDestroy, OnInit {
	AppRoute = AppRoute;
	isInternal = LocalStorageUtil.getIsInternal();
	isAdmin = LocalStorageUtil.getRole() === UserRole.ADMIN;
	activeClient$: Observable<Client>;
	subscription = new Subscription();
	clients$: Observable<Client[]>;

	constructor(
		public sideNav: SideNavService,
		private route: ActivatedRoute,
		private router: Router,
		private clientsDao: ClientsDao,
	) {
		this.subscription.add(
			this.router.events
				.pipe(filter((event) => event instanceof NavigationEnd))
				.subscribe(this.updateActiveClient.bind(this)),
		);
		this.updateActiveClient();
	}

	async ngOnInit(): Promise<void> {
		this.clients$ = this.clientsDao
			.collection()
			.streamAll((ref) =>
				LocalStorageUtil.getIsInternal()
					? ref
					: ref.where(firebase.firestore.FieldPath.documentId(), 'in', LocalStorageUtil.getClientIds()),
			)
			.pipe(map((clients) => clients.filter((client) => !client.hideInWebsite)));
	}

	async updateActiveClient() {
		let currentRoute = this.route;
		while (currentRoute.firstChild) {
			currentRoute = currentRoute.firstChild;
		}
		const clientId = await firstValueFrom(currentRoute.params.pipe(map((params) => params.clientId)));
		const shouldSetActiveClient = !this.activeClient$ || (await firstValueFrom(this.activeClient$))?.id !== clientId;
		if (shouldSetActiveClient) {
			this.activeClient$ = this.clientsDao.doc(clientId).stream();
		}
	}

	onClientChange(selectedClientId: string): void {
		this.router.navigate([`${AppRoute.Clients}/${selectedClientId}/${AppRoute.LprReport}`]);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
