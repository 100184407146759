import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
	constructor(private snackBar: MatSnackBar) {}

	open(message: string, config?: Partial<MatSnackBarConfig>) {
		const localConfig: MatSnackBarConfig = {
			duration: 2000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			politeness: 'polite',
			...config,
		};
		this.snackBar.open(message, 'dismiss', localConfig);
	}

	dismiss() {
		this.snackBar.dismiss();
	}
}
