<h1 mat-dialog-title>{{ isEdit ? 'Edit' : 'Add' }} User</h1>

<form [formGroup]="formGroup" (submit)="save()">
	<mat-dialog-content class="container">
		<div class="row">
			<div class="col d-flex align-items-center justify-content-center mb-4">
				<dix-profile-picture-selector
					[hideEditButton]="formGroup.controls.email.touched && !!(preExistingUser$ | async)"
					[identifier]="formGroup.controls.email.touched && preExistingUserId ? preExistingUserId : data.id"
				></dix-profile-picture-selector>
			</div>
			<div class="col col-8">
				<div class="row">
					<div class="col">
						<mat-form-field class="w-100" appearance="outline">
							<mat-label>First Name</mat-label>
							<input [formControl]="formGroup.controls.name" matInput />
							<mat-error *ngIf="formGroup.controls.name.errors?.required">First Name is required</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<mat-form-field class="w-100" appearance="outline">
							<mat-label>Last Name</mat-label>
							<input [formControl]="formGroup.controls.lastName" matInput />
							<mat-error *ngIf="formGroup.controls.lastName.errors?.required">lastName is required</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="!data?.existing" class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>Email</mat-label>
					<input [formControl]="formGroup.controls.email" matInput />
					<mat-error *ngIf="formGroup.controls.email.errors?.required">Email is required</mat-error>
					<mat-error *ngIf="formGroup.controls.email.errors?.email">Invalid email</mat-error>
					<mat-error *ngIf="formGroup.controls.email.errors?.exists">
						<ng-container *ngIf="data.clientId">
							A user with this email already has access to this client
						</ng-container>
						<ng-container *ngIf="!data.clientId">This email is already in use</ng-container>
					</mat-error>
					<mat-hint *ngIf="(preExistingUser$ | async) && !formGroup.controls.email.errors?.exists">
						A user with that email already exists, you can add them to this client.
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
		<div *ngIf="isAdmin && activeUserId !== data.id" class="row mt-1">
			<div class="col">
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>Role</mat-label>
					<mat-select formControlName="role">
						<mat-option *ngFor="let role of roles" [value]="role">
							{{ role | formatEnum }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="formGroup.controls.role.errors?.required">Role is required</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-checkbox formControlName="sendWelcomeEmail">Send activation email.</mat-checkbox>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<div class="d-flex gap-2 align-self-end">
			<button mat-button mat-dialog-close>Cancel</button>
			<button mat-raised-button color="primary" type="submit">
				{{ isEdit ? 'Update' : 'Add' }}
			</button>
		</div>
	</mat-dialog-actions>
</form>
