import { LogLevel } from './log-level.model';

export class LogMessage {
	level: LogLevel;
	timestamp: Date;
	message: string;
	zone?: string;
	data?: any;
	exception?: Error;

	constructor(level: LogLevel, timestamp: Date, message: string, zone?: string, data?: any, exception?: Error) {
		this.level = level;
		this.timestamp = timestamp;
		this.message = message;
		this.zone = zone;
		this.data = data;
		this.exception = exception;
	}

	/**
	 * Get the human readable version of the log level.
	 *
	 * @readonly
	 * @memberof LogMessage
	 */
	// eslint-disable-next-line getter-return, consistent-return
	public get levelText(): string {
		// eslint-disable-next-line default-case
		switch (this.level) {
			case LogLevel.All:
				return 'ALL';
			case LogLevel.Trace:
				return 'TRACE';
			case LogLevel.Debug:
				return 'DEBUG';
			case LogLevel.Info:
				return 'INFO';
			case LogLevel.Warn:
				return 'WARN';
			case LogLevel.Error:
				return 'ERROR';
			case LogLevel.Fatal:
				return 'FATAL';
			case LogLevel.Off:
			default:
				return 'OFF';
		}
	}
}
