import { Component, forwardRef, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { firstValueFrom, Observable } from 'rxjs';

export const IMAGE_STORAGE_PATH = 'profile_pictures';

@Component({
	selector: 'dix-profile-picture-selector',
	templateUrl: './profile-picture-selector.component.html',
	styleUrls: ['./profile-picture-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ProfilePictureSelectorComponent),
			multi: true,
		},
	],
})
export class ProfilePictureSelectorComponent implements ControlValueAccessor {
	isImageLoading = false;
	imageLoadingPercent$: Observable<number>;
	imageUrl: string;
	onChange: any = () => {};
	onTouched: any = () => {};
	_identifier: string;
	get identifier() {
		return this._identifier;
	}

	@Input()
	set identifier(value: string) {
		this._identifier = value;
		const file = this.storage.ref(`${IMAGE_STORAGE_PATH}/${this.identifier}.png`);
		firstValueFrom(file.getDownloadURL())
			.then((url) => {
				this.imageUrl = url;
			})
			.catch(() => {
				this.imageUrl = '';
			}); // clear on error;
	}

	@Input() hideEditButton = false;

	constructor(private storage: AngularFireStorage) {}

	writeValue(obj: any): void {
		this.imageUrl = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	async uploadFile(event: Event): Promise<void> {
		const element = event.target as HTMLInputElement;
		if (element.files.length > 0) {
			this.imageUrl = undefined;
			const file = element.files[0];
			const filePath = `${IMAGE_STORAGE_PATH}/${this.identifier}.png`;

			this.isImageLoading = true;
			const task = this.storage.upload(filePath, file);
			this.imageLoadingPercent$ = task.percentageChanges();
			const result = await task;
			this.imageUrl = await result.ref.getDownloadURL();
			this.isImageLoading = false;
		}
	}
}
