import {
	AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, QueryFn,
} from '@angular/fire/compat/firestore';

import { firstValueFrom, map, Observable } from 'rxjs';

export class CollectionInstance<T> {
	collection: AngularFirestoreCollection<T>;
	constructor(
		private afs: AngularFirestore,
		private path: string,
	) {
		this.collection = this.afs.collection<T>(this.path)
	}

	streamAll(query?: QueryFn): Observable<Array<T>> {
		return this.afs.collection<T>(this.path, query).snapshotChanges().pipe(this.mapChangeActions);
	}

	async getAll(queryFn?: QueryFn<T>): Promise<Array<T>> {
		return firstValueFrom(this.streamAll(queryFn));
	}

	private mapChangeActions = map((actions: DocumentChangeAction<T>[]) =>
		actions.map((action) => ({
			...action.payload.doc.data(),
			id: action.payload.doc.id,
			path: action.payload.doc.ref.path,
		})),
	);
}
