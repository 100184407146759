import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SnackBarService } from '../services/snack-bar.service';

export class ConfirmationConfig {
	title: string;
	question: string;
	details?: string;
	cancel?: string; // Default to 'Cancel'
	confirm: string;
	isConfirmationNegative?: boolean; // Default to false
	failureMessage?: string; // Don't show message if not present
	onConfirm: () => Promise<void>;
}

@Component({
	selector: 'dix-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
	isSubmitting: boolean;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmationConfig,
		private snackBarService: SnackBarService,
	) {}

	async onConfirm() {
		if (this.isSubmitting) return;
		this.isSubmitting = true;
		try {
			await this.data.onConfirm();
		} catch (err) {
			if (this.data.failureMessage) this.snackBarService.open(this.data.failureMessage);
		} finally {
			this.isSubmitting = false;
		}

		this.dialogRef.close();
	}
}
